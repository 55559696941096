.header {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
}

.pd10 {
    padding: 10px 0;
}

.blur1 {
    position: absolute;
    top: 5%;
    left: 15%;
    width: 300px;
    height: 300px;
    z-index: -999;
    opacity: 0.5;
    filter: blur(50px);
    background: rgb(255, 183, 247);
    background: -moz-linear-gradient(90deg, rgba(255, 183, 247, 1) 0%, rgba(224, 236, 222, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255, 183, 247, 1) 0%, rgba(224, 236, 222, 1) 100%);
    background: linear-gradient(90deg, rgba(255, 183, 247, 1) 0%, rgba(224, 236, 222, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb7f7", endColorstr="#e0ecde", GradientType=1);
}

.blur2 {
    position: absolute;
    bottom: 5%;
    right: 15%;
    width: 300px;
    height: 300px;
    z-index: -999;
    opacity: 0.5;
    filter: blur(50px);
    background: rgb(111, 241, 209);
    background: rgb(185, 249, 233);
    background: -moz-linear-gradient(90deg, rgba(185, 249, 233, 1) 0%, rgba(245, 187, 222, 1) 52%, rgba(208, 175, 244, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(185, 249, 233, 1) 0%, rgba(245, 187, 222, 1) 52%, rgba(208, 175, 244, 1) 100%);
    background: linear-gradient(90deg, rgba(185, 249, 233, 1) 0%, rgba(245, 187, 222, 1) 52%, rgba(208, 175, 244, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b9f9e9", endColorstr="#d0aff4", GradientType=1);
}

.startbtn {
    font-weight: 600;
    font-size: 1.5rem;
    background-color: #4caf50;
    padding: 12px 60px;
    border-radius: 50px;
    box-shadow: 1px 1px 20px 0px #ce93d8;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.startbtn:hover {
    box-shadow: 0 0 100px 50px #E0ECDE;
}

.ourclient {
    margin: 50px 0 0 0;
}

.ourclient img {
    opacity: 0.7;
    margin-right: 10px;
}

@media screen and (max-width: 767px) {
    #header h2 {
        font-size: 3em;
    }

    #header h5 {
        font-size: 1.2em;
    }
}