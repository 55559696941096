#nextpolicy {
    text-transform: capitalize;
    border: 2px solid #9c27b0;
    color: #9c27b0;
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 50px;
    transition: 1s cubic-bezier(0.59, 1.51, 0.98, 1);
    margin-top: 20px;
}

#nextpolicy:hover {
    color: white;
    background: #9c27b0;
}

.page {
    height: 550px;
    padding: 20px 10px;
    margin: 20px 0;
    box-shadow: 1px 1px 10px 0 #d1c4e9;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 250ms linear 1s;
}

.page>div:first-child {
    max-height: 500px;
    overflow-y: scroll;
}

.hidepane {
    opacity: 0;
    display: none;
}

.page > div::-webkit-scrollbar {
    width: 0em;
}