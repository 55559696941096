.plans .plan input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.plans .plan {
  cursor: pointer;
}

.plans .plan .plan-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  padding: 0.5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #e1e2e7;
  border-radius: 10px;
  -webkit-transition: -webkit-box-shadow 0.4s;
  transition: -webkit-box-shadow 0.4s;
  -o-transition: box-shadow 0.4s;
  transition: box-shadow 0.4s;
  transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
  position: relative;
}

.plans .plan .plan-content img {
  height: 128px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.plans .plan .plan-content div {
  font-weight: 600;
  text-align: center;
}

.plans .plan .plan-content:hover {
  -webkit-box-shadow: 0px 3px 5px 0px #e8e8e8;
  box-shadow: 0px 3px 5px 0px #e8e8e8;
}

.plans .plan input[type="radio"]:checked+.plan-content:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  background: #9c27b0;
  right: 1rem;
  top: 1rem;
  border-radius: 100%;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 0px 0px 2px #9c27b0;
  box-shadow: 0px 0px 0px 2px #9c27b0;
}

.plans .plan input[type="radio"]:checked+.plan-content {
  border: 2px solid #9c27b0;
  background: #f5eafe;
  -webkit-transition: ease-in 0.3s;
  -o-transition: ease-in 0.3s;
  transition: ease-in 0.3s;
}