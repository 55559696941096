.banner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    height: 60vh;
    color: white;
    background: rgb(153, 105, 247);
    background: -moz-linear-gradient(135deg, rgba(153, 105, 247, 1) 30%, rgba(137, 63, 217, 1) 72%);
    background: -webkit-linear-gradient(135deg, rgba(153, 105, 247, 1) 30%, rgba(137, 63, 217, 1) 72%);
    background: linear-gradient(135deg, rgba(153, 105, 247, 1) 30%, rgba(137, 63, 217, 1) 72%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9969f7", endColorstr="#893fd9", GradientType=1);
}

.banner::after {
    content: "";
    clear: both;
    display: table;
}

.banner img {
    max-width: 45%;
    height: 80%;
    object-fit: cover;
}

.banner div {
    max-width: 50%;
    padding-left: 50px;
}

@media screen and (max-width: 767px) {
    .banner div {
        max-width: 100%;
        padding-left: 10px;
    }

    .banner img {
        width: 100%;
        padding-bottom: 30px;
        height: auto;
    }
    .banner {
        flex-wrap: wrap;
        height: auto;
        padding: 30px 10px;
    }
}